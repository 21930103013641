<template>
  <div class="news">
    <Nav paths="/" />
    <div class="banner">
      <div class="banner-bg"></div>
    </div>
    <div class="news-title-row">
      <div class="en-title">News Dynamic</div>
      <div class="chs-title">新闻动态</div>
    </div>
    <div class="news-list-all">
      <div class="news-items" v-loading="loading">
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item, index) in new_list" :key="index" style="height: 540px;margin-bottom:60px;">
            <el-card :body-style="{ padding: '0px',border:'0' }" shadow="hover">
              <img :src="realmURL + item.top_pic" class="image">
              <div class="news-content">
                <div class="news-content-title">{{item.title}}</div>
                <p class="news-content-value">{{item.summry}}</p>
                <div class="news-clearfix">
                  <time class="time">{{item.add_time | dateFilter}}</time>
                  <el-tag type="info">{{item.type == 1 ? '公司新闻' : '行业新闻'}}</el-tag>
                </div>
                <div class="news-selects">
                  <el-button type="primary" plain @click="selector(item.id)">查看详情</el-button>
                </div>
              </div>
            </el-card>
            <el-empty description="暂无数据" :image-size="250" v-if="new_list.length == 0"></el-empty>
          </el-col>
        </el-row>
        <el-col v-if="new_list=='-1'">
          <el-result icon="error" title="内容加载失败！" subTitle="请联系管理员或刷新页面重试">
            <template slot="extra">
              <el-button type="primary" size="medium" @click="resetPage">刷新</el-button>
            </template>
          </el-result>
        </el-col>
      </div>
    </div>
    <div class="news-pages">
      <el-pagination background layout="prev, pager, next" :page-size="12" :total="totals" hide-on-single-page @current-change="changepage" />
    </div>
    <el-backtop :visibility-height="300" :bottom="100" />
  </div>
</template>

<script>
import Nav from '@/components/nav'
export default {
  data () {
    return {
      new_list: [],
      totals: 0,
      loading: true,
    }
  },
  methods: {
    getNewsList (curr) {
      this.$axios.post('http://freeze.ganmiaoedu.com/website/site/getnewslist', {
        page: curr, // 页数
        limit: '12', // 条数
      })
        .then(res => {
          if (res.data.code == 1) {
            setTimeout(() => {
              this.loading = false
              this.totals = res.data.data.count
              this.new_list = res.data.data.data
            }, 1000);
          } else {
            setTimeout(() => {
              this.loading = false
              this.new_list = '-1'
            }, 5000);
          }
        })
    },
    selector (id) {
      // atype: 1 公司动态，2 新闻
      this.$router.push({ path: '/detail', query: { id: id, atype: 2 } })
    },
    resetPage () {
      location.reload();
    },
    changepage (currPage) {
      this.loading = true
      this.getNewsList(currPage)
      setTimeout(() => {
        this.loading = false
      }, 1000);
    },
  },
  created () {
    this.getNewsList(1)
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Nav,
  }
}
</script>

<style lang="less" scoped>
.news {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: 500px;
  .banner {
    .banner-bg {
      width: 100%;
      min-width: 1600px;
      height: 750px;
      background: url("../assets/home.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .news-title-row {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1400px;
    margin: 80px auto;
    .en-title {
      font-size: 48px;
      font-weight: bold;
      color: #e6e6e6;
      letter-spacing: 10px;
      text-align: center;
    }
    .chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      letter-spacing: 5px;
      text-align: center;
      margin-top: -30px;
    }
  }
  .news-list-all {
    position: relative;
    display: flex;
    width: 1400px;
    margin: 0 auto;
    .news-items {
      position: relative;
      width: 1400px;
      min-height: 500px;
      /deep/ .image {
        width: 100%;
        height: 264px;
        object-fit: cover;
      }
      .el-card__body {
        width: 440px !important;
      }
      .news-content {
        padding: 0 20px;
        .news-content-title {
          font-size: 26px;
          font-weight: bold;
          color: #000;
          letter-spacing: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 20px 0;
        }
        .news-content-value {
          height: 60px;
          font-size: 20px;
          color: #6c6d6e;
          line-height: 30px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
          text-indent: 40px;
          margin: 0 0 20px;
        }
        .news-clearfix {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .time {
            font-size: 22px;
            color: #b5b6b6;
          }
          /deep/ .el-tag.el-tag--info {
            font-size: 16px;
            padding: 0 12px;
            color: #b5b6b6;
            background-color: #e6e6e6;
            border-radius: 15px;
          }
        }
        .news-selects {
          display: flex;
          justify-content: flex-end;
          margin: 20px 0;
          /deep/ .el-button--primary.is-plain {
            color: #6c6d6e;
            font-size: 20px;
            border-radius: 10px;
            border-color: #a9a9a9;
            background: #fff;
          }
          /deep/ .el-button--primary.is-plain:focus,
          /deep/ .el-button--primary.is-plain:hover {
            background: #409eff;
            border-color: #409eff;
            color: #fff;
          }
        }
      }
    }
  }
  .news-pages {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1400px;
    padding: 50px 0;
    margin: 0 auto;
    /deep/ .el-pagination.is-background .btn-next,
    /deep/ .el-pagination.is-background .btn-prev,
    /deep/ .el-pagination.is-background .el-pager li {
      min-width: 50px;
      min-height: 40px;
      line-height: 40px;
      font-size: 20px;
    }
  }
}
</style>